/** A list of 17 color names used for WAI-ARIA compliance. */
const colorNames: string[] = [
  "white",
  "black",
  "grey",
  "red",
  "orange",
  "brown",
  "gold",
  "olive",
  "yellow",
  "lime",
  "green",
  "teal",
  "cyan",
  "blue",
  "violet",
  "magenta",
  "pink",
];
export default colorNames;
